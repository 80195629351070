<template>
  <div class="Orders">
    <div class="Orders__head">
      <h2 class="ft-h3">{{ $t('orders') }} ({{ ordersContainer.total }})</h2>

      <Input
        :placeholder="$t('order.search.placeholder')"
        v-model="query"
        class="Orders__search"
        v-if="ordersContainer.total > 4"
      />
    </div>
    <SkeletonTransition
      :ready="
        ordersContainer.status === Progress.COMPLETE || ordersContainer.status === Progress.ERROR
      "
    >
      <template #default>
        <Alert v-if="ordersContainer.status === Progress.ERROR" :level="AlertLevel.ERROR" hideIcon>
          {{ $t('order.message.download_error') }}
        </Alert>
        <div v-else-if="ordersContainer.searchedTotal > 0" class="Orders__cards">
          <OrderCard
            v-for="order in ordersContainer.pageOrders"
            :key="order.order_number"
            :order="order"
          />
        </div>
        <p v-else class="Orders__noResult">{{ $t('order.message.found_no_orders') }}</p>
      </template>
      <template #skeleton>
        <div class="Orders__cards">
          <!-- <OrderCardSkeleton v-for="i in 4" :key="i" /> -->
          <DetailsCardSkeleton v-for="i in 4" :key="i" />
        </div>
      </template>
    </SkeletonTransition>

    <Pagination
      v-if="ordersContainer.totalPages > 1"
      :page="ordersContainer.page"
      :total="ordersContainer.totalPages"
      :scrollTo="$el"
      class="Orders__pagination"
      @change="ordersContainer.setPage"
    />
  </div>
</template>

<script>
import { SkeletonTransition, DetailsCardSkeleton, OrderCardSkeleton } from '@components/Skeleton';
import Alert, { AlertLevel } from '@components/Alert';
import Pagination from '@components/Pagination2';
import Input from '@scenes/SelfserviceScene/components/Input';
import OrderCard from '@scenes/SelfserviceScene/components/OrderCard';
import Progress from '@types/Progress';

export default {
  name: 'Orders',

  components: {
    SkeletonTransition,
    DetailsCardSkeleton,
    OrderCardSkeleton,
    Alert,
    Pagination,
    Input,
    OrderCard,
  },

  enums: {
    AlertLevel,
    Progress,
  },

  computed: {
    query: {
      get() {
        return this.ordersContainer.searchQuery;
      },
      set(value) {
        this.ordersContainer.setSearchQuery(value);
      },
    },
  },

  inject: {
    ordersContainer: {
      default() {
        console.error('Missing OrdersContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Orders {
  .Orders__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;

    @include maxBreakpoint(768) {
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
    }
  }

  .Orders__status {
    color: #808080;
  }

  .Orders__cards {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .Orders__pagination {
    justify-content: center;
    margin-top: 20px;
  }

  .Orders__noResult {
    font-size: 18px;
    color: #aeaeae;
    text-align: center;
    margin-top: 60px;
  }

  .Orders__search {
    width: 256px;

    @include maxBreakpoint(768) {
      width: 100%;
    }
  }
}
</style>
