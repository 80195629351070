<template>
  <ResizeObserver v-slot="{ width }">
    <div :class="['OrdersFilters', width < 700 && 'OrdersFilters--mobile ']">
      <div class="OrdersFilters__topBar">
        <DateRangePicker
          :startDate="ordersContainer.startDate"
          :endDate="ordersContainer.endDate"
          :showAsModal="width < 700"
          :disabled="refinementsLength !== 0"
          class="OrdersFilters__dateRangePicker"
          @change="onDateRangeChange"
        />
        <Button
          type="light"
          icon="far fa-filter"
          class="OrdersFilters__filterButton"
          :count="refinementsLength"
          @click="showFilters = !showFilters"
        >
          {{ showFilters ? $t('order.search.hide_filter') : $t('order.search.show_filter') }}
        </Button>
      </div>
      <Collapse>
        <div v-if="showFilters">
          <div class="OrdersFilters__expansion">
            <div class="OrdersFilters__filters">
              <Input
                :label="$t('order.order_number')"
                class="OrdersFilters__filter"
                v-model="inputs.order_number"
              />
              <Input
                :label="$t('order.invoice_number')"
                class="OrdersFilters__filter"
                v-model="inputs.invoice_number"
              />
              <Input
                :label="$t('product.item_number')"
                class="OrdersFilters__filter"
                v-model="inputs.item_number"
              />
              <Input
                :label="$t('vehicle.license_plate')"
                class="OrdersFilters__filter"
                v-model="inputs.license_plate"
              />
              <Input
                v-if="$isEnabled(Features.VEHICLE_ID_VISIBLE)"
                :label="$t('vehicle.id')"
                class="OrdersFilters__filter"
                v-model="inputs.vehicle_id"
              />
              <Input
                :label="$t('product.reference')"
                class="OrdersFilters__filter"
                v-model="inputs.reference_number"
              />
            </div>
            <Button :color="colors.WHITE" @click="onClear">
              {{ $t('order.search.clear_button') }}
            </Button>
            <Button icon="far fa-search" @click="onSearch">
              {{ $t('order.search.search_button') }}
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '@components/ResizeObserver';
import Collapse from '@transitions/Collapse';
import { isEmpty } from '@helpers/object';
import { Button, colors, sizes } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';
import DateRangePicker from '@components/DateRangePicker';
import Features from '@types/Features';
import dayjs from 'dayjs';
import { Filters } from '@scenes/SelfserviceScene/containers/OrdersContainer';

export default {
  name: 'OrdersFilters',

  components: {
    ResizeObserver,
    Collapse,
    Button,
    Input,
    DateRangePicker,
  },

  enums: {
    colors,
    sizes,
    Features,
  },

  data() {
    return {
      showFilters: false,
      inputs: {
        order_number: '',
        invoice_number: '',
        item_number: '',
        license_plate: '',
        vehicle_id: '',
        reference_number: '',
      },
    };
  },

  computed: {
    refinementsLength() {
      if (!this.ordersContainer.refinements) return 0;
      return Object.keys(this.ordersContainer.refinements).length;
    },
  },

  watch: {
    'inputs.order_number'(to) {
      this.$nextTick(() => (this.inputs.order_number = this.stringToInteger(to)));
    },
    'inputs.invoice_number'(to) {
      this.$nextTick(() => (this.inputs.invoice_number = this.stringToInteger(to)));
    },
  },

  created() {
    this.updateInputs();
  },

  methods: {
    stringToInteger(string) {
      let integer = '';
      for (let i = 0; i < string.length; i++) {
        if (this.isIntegerChar(string[i])) integer += string[i];
      }
      return integer;
    },
    isIntegerChar(char) {
      const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      return validChars.indexOf(char) !== -1;
    },
    updateInputs() {
      if (!this.ordersContainer.refinements) return;
      Object.keys(this.ordersContainer.refinements).forEach(key => {
        if (this.ordersContainer.refinements[key]) {
          this.inputs[key] = this.ordersContainer.refinements[key];
          this.showFilters = true;
        }
      });
    },
    onDateRangeChange(range) {
      this.ordersContainer.setDates(range);
      this.ordersContainer.search([Filters.DATES]);
    },
    onSearch() {
      const refinements = this.getRefinements();
      this.ordersContainer.setRefinements(refinements);
      if (isEmpty(refinements)) this.ordersContainer.search([Filters.DATES]);
      else this.ordersContainer.search([Filters.REFINEMENTS]);
    },
    getRefinements() {
      return Object.keys(this.inputs).reduce((refinements, key) => {
        if (this.inputs[key]) refinements[key] = this.inputs[key];
        return refinements;
      }, {});
    },
    onClear() {
      this.clearInputs();
      this.ordersContainer.clearRefinements();
      this.ordersContainer.search([Filters.DATES]);
    },
    clearInputs() {
      Object.keys(this.inputs).forEach(key => (this.inputs[key] = ''));
    },
  },

  inject: {
    ordersContainer: {
      default() {
        console.error('Missing OrdersContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrdersFilters {
  display: flex;
  flex-direction: column;

  .OrdersFilters__topBar {
    display: flex;

    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .OrdersFilters__dateButtonLabel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .OrdersFilters__dateButtonIcon {
    margin: 0;
  }

  &--mobile .OrdersFilters__filterButton,
  &--mobile .OrdersFilters__dateRangePicker,
  &--mobile .OrdersFilters__dateButton {
    width: 100%;
  }

  .OrdersFilters__label {
    margin: auto;
    padding: 10px 0px;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
  }

  .OrdersFilters__expansion {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .OrdersFilters__filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .OrdersFilters__filter {
    flex: 1;
    min-width: 240px;
  }
}
</style>
