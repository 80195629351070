<template>
  <SelfservicePageLayout :title="$route.meta.title">
    <template #controls>
      <TransferOrderButton v-if="$isEnabled(Features.ORDER_TRANSFER)" />
    </template>
    <template #default>
      <RmaContainer ref="rmaContainer" v-slot="{}">
        <OrdersContainer
          v-if="accountContainer.account !== null"
          routing
          :searchableAttributes="['order_number', 'lines.product.item_number']"
          :initialStartDate="getStartDate()"
          :initialEndDate="getDateToday()"
        >
          <div class="OrdersScene">
            <OrdersFilters class="OrdersScene__filters" />
            <Orders class="OrdersScene__orders" />
          </div>
        </OrdersContainer>
      </RmaContainer>
    </template>
  </SelfservicePageLayout>
</template>

<script>
import OrdersContainer from '@scenes/SelfserviceScene/containers/OrdersContainer';
import Orders from './components/Orders';
import OrdersFilters from './components/OrdersFilters';
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';

import TransferOrderButton from './components/TransferOrderButton';
import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import dayjs from 'dayjs';
import Features from '@types/Features';
export default {
  name: 'OrdersScene',

  components: {
    OrdersContainer,
    OrdersFilters,
    Orders,
    RmaContainer,
    TransferOrderButton,
    SelfservicePageLayout,
  },

  enums: {
    Features,
  },

  methods: {
    getStartDate() {
      if (this.accountContainer.isPrivate) return this.getDateOneYearBack();
      if (this.accountContainer.isDealer) return this.getDateOneMonthBack();
      return this.getDateToday();
    },
    getDateToday() {
      return dayjs().toDate();
    },
    getDateOneMonthBack() {
      return dayjs()
        .subtract(1, 'month')
        .add(1, 'day')
        .toDate();
    },
    getDateOneYearBack() {
      return dayjs()
        .subtract(1, 'year')
        .add(1, 'day')
        .toDate();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrdersScene {
  .OrdersScene__filters {
    margin-bottom: 30px;
  }
  .OrdersScene__top {
    display: flex;
    justify-content: space-between;
  }
}
</style>
