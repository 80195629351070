<template>
  <div class="TransferOrderButton">
    <Button type="light" icon="far fa-plus" @click="$refs.modal.open()">
      {{ $t('order.transfer.control') }}
    </Button>
    <Validator v-slot="{ valid, showError }" ref="validator">
      <Modal ref="modal" :title="$t('order.transfer.control')" :working="working">
        <template #default>
          <p>{{ $t('order.transfer.description') }}</p>
          <Form
            class="TransferOrderButton__form"
            @submit="valid ? submit() : showError()"
            :locked="working"
          >
            <template #default>
              <FadeTransition :duration="0.2" :delay="0" transform-height>
                <Alert v-if="showMissingInputsError" hideIcon :level="AlertLevel.WARNING">
                  {{ $t('order.transfer.message.missing_input') }}
                </Alert>
                <Alert hideIcon v-else-if="success" :level="AlertLevel.SUCCESS">
                  {{ $t('order.transfer.message.success') }}
                </Alert>
                <Alert
                  hideIcon
                  v-else-if="error === 'unknown_combination'"
                  :level="AlertLevel.WARNING"
                >
                  {{ $t('order.transfer.message.not_found') }}
                </Alert>
                <Alert v-else-if="error" :level="AlertLevel.ERROR">
                  {{ $t('error_messages.try_again') }}
                </Alert>
              </FadeTransition>
              <Input
                id="invoiceNumber"
                name="invoiceNumber"
                type="text"
                :label="$t('order.invoice_number')"
                required
                pattern="^\d*$"
                :patternErrorMessage="$t('order.transfer.invoice_number.invalid')"
                validateOnBlur
                v-model="inputs.invoiceNumber"
              />
              <div class="TransferOrderButton__formGroup">
                <Input
                  id="receiptId"
                  name="receiptId"
                  type="text"
                  :label="$t('order.transfer.receipt_id')"
                  pattern="^\d{5}$"
                  :patternErrorMessage="$t('order.transfer.receipt_id.invalid')"
                  v-model="inputs.receiptId"
                />
                <Input
                  id="orderNumber"
                  name="orderNumber"
                  type="text"
                  :label="$t('order.transfer.order_number')"
                  pattern="^\d*$"
                  :patternErrorMessage="$t('order.transfer.order_number.invalid')"
                  v-model="inputs.orderNumber"
                />
              </div>
            </template>
          </Form>
        </template>
        <template #controls="{ close }">
          <Button :color="ButtonColors.WHITE" @click.stop="close">{{ $t('cancel') }}</Button>
          <Button @click="valid ? submit() : showError()">{{ $t('order.transfer.submit') }}</Button>
        </template>
      </Modal>
    </Validator>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';
import { Modal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { postTransfer } from '@services/thansen/orders';
import Alert, { AlertLevel } from '@components/Alert';
import Validator from '@containers/Validator';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';
import Form from '@layouts/Form';
import FadeTransition from '@transitions/Fade';

export default {
  name: 'TransferOrderButton',

  components: {
    Modal,
    StoreFinder,
    Button,
    Alert,
    Form,
    Input,
    Validator,
    FadeTransition,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    AlertLevel,
    Autocompletes,
  },

  data() {
    return {
      working: false,
      error: '',
      success: false,
      showMissingInputsError: false,
      inputs: {
        invoiceNumber: '',
        receiptId: '',
        orderNumber: '',
      },
    };
  },

  computed: {
    hasRequiredInputs() {
      return this.inputs.orderNumber || this.inputs.receiptId;
    },
  },

  watch: {
    'inputs.invoiceNumber'() {
      this.clearError();
    },
    'inputs.receiptId'() {
      this.clearError();
    },
    'inputs.orderNumber'() {
      this.clearError();
    },
    hasRequiredInputs(to) {
      this.$refs.validator.updateItem('hasRequiredInputs', to);
      if (to) {
        this.showMissingInputsError = false;
      }
    },
  },

  mounted() {
    this.$refs.validator.addItem(
      'hasRequiredInputs',
      false,
      () => (this.showMissingInputsError = true),
    );
  },

  methods: {
    submit() {
      this.working = true;
      this.success = false;

      postTransfer({
        invoice_number: Number(this.inputs.invoiceNumber),
        receipt_id: this.inputs.receiptId,
        order_number: Number(this.inputs.orderNumber),
      })
        .then(response => {
          this.clearInputs();
          this.success = true;
          this.working = false;
        })
        .catch(errors => {
          if (Array.isArray(errors) && errors.length) {
            this.error = errors[0].code;
          } else {
            this.error = 'error';
          }
          this.working = false;
        });
    },
    clearInputs() {
      this.inputs.invoiceNumber = '';
      this.inputs.receiptId = '';
      this.inputs.orderNumber = '';
    },
    clearError() {
      this.error = '';
      this.showMissingInputsError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.TransferOrderButton {
  .TransferOrderButton__storefinder {
    min-height: 300px;
    height: 40vh;
  }

  .TransferOrderButton__headline {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1em;
  }

  .TransferOrderButton__formGroup {
    display: flex;
    gap: 20px;
  }
}
</style>
